import React from "react";
import {faChain, faChevronRight} from "@fortawesome/free-solid-svg-icons";

import "./styles/cv.css";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Link} from "react-router-dom";

const CV = () => {
    return (
        <div className="cv">
            <div className="cv-container">
                <div className="cv-header">
                    <div className="cv-icon">
                        <FontAwesomeIcon icon={faChain}/>
                    </div>
                    <div className="cv-download-link">
                        <Link to={"/Voitovas_CV.pdf"} target="_blank" download>
                            Download CV{" "}
                            <FontAwesomeIcon
                                style={{fontSize: "10px"}}
                                icon={faChevronRight}
                            />
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CV;
