import React from "react";

import "./styles/certification.css";

const Certification = (props) => {
	const { logo, title, description } = props;

	return (
		<React.Fragment>
			<div className="certification">
				<div className="certification-container">
					<div className="certification-logo">
						<img src={logo} alt="logo" />
					</div>
					<div className="certification-title">{title}</div>
					<div className="certification-description">{description}</div>
				</div>
			</div>
		</React.Fragment>
	);
};

export default Certification;
