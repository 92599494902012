const SEO = [
	{
		page: "home",
		description:
			"Experienced software engineer specializing in web development and Sylius eCommerce, delivering innovative e-shop solutions for 6+ years.",
		keywords: ["Andrejus Voitovas", "Andrejus V", "Voitovas"],
	},
	{
		page: "about",
		description:
			"Dedicated to perfection and improvement, I deliver high-quality web applications with strong attention to detail and a passion for excellence.",
		keywords: ["Andrejus Voitovas", "Andrejus V", "Voitovas"],
	},
	{
		page: "articles",
		description:
			"Software engineer sharing expertise through articles on technical guides, industry insights, and reflections on challenges and innovations.",
		keywords: ["Andrejus Voitovas", "Andrejus V", "Voitovas"],
	},
	{
		page: "certifications",
		description:
			"Committed to professional growth, I pursue certifications that reflect my dedication to mastering new skills and delivering exceptional results in software engineering.",
		keywords: ["Andrejus Voitovas", "Andrejus V", "Voitovas"],
	},
];

export default SEO;
