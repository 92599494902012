import React from "react";

import Certification from "./certification";

import INFO from "../../data/user";

import "./styles/allCertifications.css";

const AllCertifications = () => {
	return (
		<div className="all-certifications-container card">
			{INFO.certifications.map((certification, index) => (
				<div className="all-certifications-certification" key={index}>
					<Certification
						logo={certification.logo}
						title={certification.title}
						description={certification.description}
					/>
				</div>
			))}
		</div>
	);
};

export default AllCertifications;
