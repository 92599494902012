import React, {useEffect} from "react";
import {Helmet} from "react-helmet";

import NavBar from "../components/common/navBar";
import Footer from "../components/common/footer";
import Logo from "../components/common/logo";
import AllCertifications from "../components/certifications/allCertifications";

import INFO from "../data/user";
import SEO from "../data/seo";

import "./styles/certifications.css";

const Certifications = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const currentSEO = SEO.find((item) => item.page === "certifications");

    return (
        <React.Fragment>
            <Helmet>
                <title>{`Certifications | ${INFO.main.title}`}</title>
                <meta name="description" content={currentSEO.description}/>
                <meta
                    name="keywords"
                    content={currentSEO.keywords.join(", ")}
                />
            </Helmet>

            <div className="page-content">
                <NavBar active="certifications"/>
                <div className="content-wrapper">
                    <div className="certifications-logo-container">
                        <div className="certifications-logo">
                            <Logo width={46}/>
                        </div>
                    </div>
                    <div className="certifications-container">
                        <div className="title certifications-title">
                            Certifications & Achievements
                        </div>

                        <div className="subtitle certifications-subtitle">
                            Throughout my career, I have pursued certifications and achievements that demonstrate my
                            dedication to professional growth and technical expertise. This collection reflects my
                            commitment to staying current with industry standards, mastering new skills, and delivering
                            exceptional results in software engineering. Each certification is a milestone in my journey
                            toward continuous learning and innovation.
                        </div>

                        <div className="certifications-list">
                            <AllCertifications/>
                        </div>
                    </div>
                    <div className="page-footer">
                        <Footer/>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Certifications;
