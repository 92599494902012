const INFO = {
	main: {
		title: "voitovas.dev - SSE",
		name: "Andrejus Voitovas",
		email: "andrejus.voitovas@gmail.com",
		logo: "../logo.png",
	},

	socials: {
		github: "https://github.com/andriusvo",
		linkedin: "https://www.linkedin.com/in/andrejus-voitovas/",
	},

	homepage: {
		title: "Senior Software Engineer",
		description:
			"As a software engineer with a specialization in web development, I have been dedicated to creating innovative solutions for the past 6 years. My primary focus has been on developing e-shops using the Sylius eCommerce platform, and I have become an expert in utilizing its capabilities to their fullest potential.",
	},

	about: {
		title: "Nice to meet you there!",
		description:
		"In my work, I constantly strive for perfection and am always on the lookout for opportunities to improve. My approach is to deliver the best possible results to my clients, as I have been taught to do. With my strong attention to detail and passion for the craft, I have consistently delivered high-quality web applications."
	},

	articles: {
		title: "Articles",
		description:
			"As a professional software engineer, I enjoy sharing my knowledge and experiences through writing. My articles cover a range of topics, from in-depth technical guides and industry insights to personal reflections on challenges and innovations in the field.",
	},

	certifications: [
		{
			title: "AWS Certified Developer - Associate",
			description: "Issued Jun 2022",
			logo: "aws.jpeg",
		},

		{
			title: "AWS Cloud practicioner",
			description: "Issued Mar 2021",
			logo: "aws.jpeg",
		},

		{
			title: "Zend PHP Certification",
			description: "Issued Dec 2019",
			logo: "zend.jpeg",
		},
	],
};

export default INFO;
